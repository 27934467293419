import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import { QueriesKeys } from "interfaces/queriesKeys";
import { getPaymentsStatisticApi } from "pages/citizens/api/api.statistic";
import React, { FC } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { FormItem, Tag, colors } from "tap2visit-ui-kit";
import TableStore from "../Table/store/Table.store";
import { getAmount } from "../Table/utils/getAmount";
import dayjs from "dayjs";
import { getFormtattedSum } from "utils/format/getFormtattedSum";
import { observer } from "mobx-react-lite";

interface IStatistic {}

const Statistic: FC<IStatistic> = (props) => {
	const tableFilterStore = useTableFilterContext();

	const statistic = useQuery({
		queryFn: () => {
			const params = {
				complexIds: tableFilterStore.selectedComplexIds,
				buildingIds: tableFilterStore.selectedBuildingsIds,
				entranceIds: tableFilterStore.selectedEntrancesIds,
				floorIds: tableFilterStore.selectedFloorsIds,
				buildingObjectIds: tableFilterStore.selectedBuildingObjectIds,
				clientFullNameSearch: TableStore.clientFullNameSearch,
				accountNumberSearch: TableStore.accountNumberSearch,
				amount: getAmount(TableStore.amount),
				startCreationTime: TableStore?.startCreationTime ? dayjs(TableStore?.startCreationTime).toISOString() : undefined,
				endCreationTime: TableStore?.endCreationTime
					? dayjs(TableStore?.endCreationTime)
							.add(1, "day")
							.toISOString()
					: undefined,
			};

			return getPaymentsStatisticApi({
				params: params,
			});
		},
		queryKey: [
			QueriesKeys.paymentsStatistics,
			tableFilterStore.selectedComplexIds,
			tableFilterStore.selectedBuildingsIds,
			tableFilterStore.selectedEntrancesIds,
			tableFilterStore.selectedFloorsIds,
			tableFilterStore.selectedBuildingObjectIds,
			TableStore.clientFullNameSearch,
			TableStore.accountNumberSearch,
			TableStore.amount,
			TableStore?.startCreationTime,
			TableStore?.endCreationTime,
		],
	});

	return (
		<>
			{statistic.data && (
				<Container className="sdf">
					<FormItem
						classNameFormItem="receipts-statistic"
						labelTextStyle={{ color: colors.textNeutralPrimary }}
						label="Cумма начисления:"
						labelPosition="horizontal">
						<Tag color="grey" text={getFormtattedSum(statistic.data.totalAmount?.toString())} />
					</FormItem>

					<FormItem
						classNameFormItem="receipts-statistic"
						labelTextStyle={{ color: colors.textNeutralPrimary }}
						label="Cумма начисления без комиссии:"
						labelPosition="horizontal">
						<Tag color="grey" text={getFormtattedSum(statistic.data.totalAmountWithoutCommission?.toString())} />
					</FormItem>

					<FormItem
						classNameFormItem="receipts-statistic"
						labelTextStyle={{ color: colors.textNeutralPrimary }}
						label="Cумма комиссии:"
						labelPosition="horizontal">
						<Tag color="grey" text={getFormtattedSum(statistic.data.totalCommission?.toString())} />
					</FormItem>
				</Container>
			)}
		</>
	);
};

export default observer(Statistic);

const Container = styled.div`
	display: flex;
	gap: 16px;

	.receipts-statistic {
		display: flex !important;
		align-items: center !important;
	}
`;
